<template>
  <div
    class="absolute h-full overflow-hidden border-solid border-[4px] border-[#000] bg-[#000000bf]"
  >
    <div
      :style="progressBarStyle"
      class="absolute top-0 left-0 h-full transition-all duration-500 ease-linear"
    />
    <div
      class="flex items-center absolute justify-center text-regular-16 !text-white w-full h-full text-center"
    >
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';

const props = defineProps<{
  text: string;
  progress: number;
}>();

const { progress } = toRefs(props);

const progressBarStyle = computed(() => {
  return {
    width: `${progress.value}%`,
    background: progressBarBackground.value,
  };
});

const progressBarBackground = computed(() => {
  if (progress.value >= 75) {
    return 'linear-gradient(to right, #ad9508, #5b9a11)';
  } else if (progress.value >= 50) {
    return 'linear-gradient(to right, #f2b01e, #ad9508)';
  } else if (progress.value >= 25) {
    return 'linear-gradient(to right, #f27011, #f2801e)';
  } else {
    return 'linear-gradient(to right, #f63a0f, #f27011)';
  }
});
</script>
